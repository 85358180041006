// ======================================================================
// Forge Lite | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

// Menu

var hamburger = document.querySelector(".menu-butt");
var menu = document.querySelector(".site-menu");
var bodyclasses = document.body;


function menuTogggle() {
      // If active...
      if (menu.classList.contains('site-menu-active')) {
        // Hide
        menu.classList.remove('site-menu-active');
        menu.setAttribute('aria-hidden', 'true');
        hamburger.classList.remove('is-active');
        hamburger.setAttribute('aria-expanded', 'false');
  
        bodyclasses.classList.remove('no-scroll');
        bodyclasses.classList.remove('menu-open');
  
      } else {
        // Show
        menu.classList.add('site-menu-active');
        menu.setAttribute('aria-hidden', 'false');
        hamburger.classList.add('is-active');
        hamburger.setAttribute('aria-expanded', 'true');
  
        bodyclasses.classList.add('no-scroll');
        bodyclasses.classList.add('menu-open');
  
        // Set focus on first link
        // menu.children[0].children[0].children[0].focus();
      }
  
}



if (hamburger) {
  hamburger.addEventListener('click', function() {
    menuTogggle()
  }, false);

}

var menulinks = document.querySelectorAll('.site-menu a');

if (menulinks) {
  menulinks.forEach(item => {
    item.addEventListener('click', event => {
      menuTogggle();
    })
  })
}